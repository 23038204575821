import { Col, Button, Input } from 'antd';
import styled from 'styled-components';

export const Wrap = styled.div`
  padding-top: 2.8rem;
  padding-bottom: 3.25rem;
  padding-inline: 5rem;

  .info_character {
    margin-bottom: 8px;
  }
  .footer_action {
    .ant-col:first-child {
      display: flex;
      .btn_delete {
        margin-left: 20px;
        height: 45px;
      }
      .disable {
        pointer-events: none;
        cursor: default;
        background: #E7E3E8 ;
        color: #000000 ;
        border: 1px solid #d9d9d9;
      }
    }
  }

  .hidden_col {
    display: none;
  }

  @media only screen and (max-width: 1440px) {
    padding-inline: 4rem;
  } 
  @media only screen and (max-width: 1280px) {
    padding-inline: 10px;
  }
  .footer_action {
    .ant-col:first-child {
      display: flex;
      .btn_delete {
        margin-left: 20px;
        height: 45px;
      }
      .disable {
        pointer-events: none;
        cursor: default;
        background: #E7E3E8 ;
        color: #000000 ;
        border: 1px solid #d9d9d9;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding-inline: 2rem;
  } 

  @media only screen and (max-width: 768px ) {
    padding-inline: 2rem;
  }

  .component_print {
    display: none;
  }

  .element_print {
    @media print {
      @page {
        size: A4 portrait; 
        margin: 20px;
      }
      /* table { page-break-inside: avoid;}
      thead {display: table-row-group;} */
    }
  }

`;
export const ActionBreadcrumb = styled.div`
  display: flex;
  .backHome{
    .ant-breadcrumb-link{
      .breadcrumb-point{
        display: none;
      }
      a{
        display: flex;
        align-items: center;
        span{
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }
`
export const ColWraper = styled(Col)`
  display: grid;
  /* row-gap: 5px; */
  border-right: 1px solid #c9c9c9;
  
  border-collapse: separate;
  padding: 0 !important;
  @media only screen and (max-width: 1440px) {
    border-left: 1px solid #C9C9C9;
  }

  @media only screen and (max-width: 768px ) {
   margin-bottom: 25px;
  }
  .info_title{
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    height: 30px;
    border-bottom: 1px solid #c9c9c9;
    padding: 0 8px;
    background: #f5f5f5; 
    display: flex;
    align-items: center;
    border-top: 1px solid #c9c9c9;
     @media only screen and (max-width: 1280px ) {
      font-size: 10px;
    }
  }

  .info_data{
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    min-height: 40px;
    padding: 0 8px;
    background: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c9c9c9;
    @media only screen and (max-width: 1280px ) {
      font-size: 10px;
    }
  }
`
export const SuccessBtn = styled(Button)`
  background: #F0A7AB;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #7D0006;
  font-family: 'Public Sans';
  height: 50px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #F0A7AB;

  &:hover{
    color: #7D0006 !important;
    border-color: #F0A7AB !important;
    opacity: 0.7;
  }
`
export const ColButton = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  top: 15px;

  .ant-btn:last-child {
    margin-left: 8px;
  }

  .disable {
    pointer-events: none;
    cursor: default;
    background: #E7E3E8 ;
    color: #000000 ;
    border: 1px solid #d9d9d9;
  }
`
export const UpdateBtn = styled.button`
  background: #7D0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #7D0006;
  margin-top: 20px;
  margin-bottom: -20px;
  &:hover{
    opacity: 0.7;
  }
`

export const FailureBtn = styled.button`
  background: #7D0006;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #FFFFFF;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #7D0006;
`

export const NormalButton = styled.button`
  background: #E7E3E8;
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #000000;
  font-family: 'Public Sans';
  height: 45px;
  min-width: 13rem;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #E7E3E8;
`

export const GroupButton = styled(Col)`
  display: flex;
  justify-content: flex-end;

   @media only screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;

    button{
      margin-top: 1rem;
      width: 45px;
    }
  }

`
export const InputWrapper = styled(Input)`
  margin-top: 10px;
  .ant-input-wrapper{
    height: 50px;

    .ant-input-group-addon{
      width: 60px;
      background-color: #f5f5f5;
      border-color: #c9c9c9;
    }
    input{
      height: 50px;
      border-color: #c9c9c9;

    }
  }
`
export const InfomationStudent = styled.div`
  padding: 0 10px;
  border-radius: 8px;
  .ant-row{
    border-radius: 8px;
    .ant-col {
      &:first-child{
        border-left: 1px solid #C9C9C9;
      }
    }
  }
  .info_character {
    margin-top: 20px;
    .ant-col {
      &:nth-child(2){
        border-left: 1px solid #C9C9C9;
      }
    }
  }
`