/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import BreadcrumbComponent from "Components/Breadcrumb";
import TableScoreComponent from "Components/Table/ScoreTable";
import { RESPONSE_STATUS } from "Constants";
import React, {
  useState,
  useMemo,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  ActionBreadcrumb,
  ColButton,
  ColWraper,
  GroupButton,
  InfomationStudent,
  InputWrapper,
  SuccessBtn,
  UpdateBtn,
  Wrap,
} from "./styled";
import TableResult from "./TableResult";
import ModalAddCourse from "./ModalAddCourse";
import ModalEditCourse from "./ModalEditCourse";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { exportSchedule } from "Utils/export-csv";
import ReactToPrint from "react-to-print";
import actions from "Store/Actions";
import { Notification } from "Components";
import { FailureBtn } from "Components/Button";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { convertValueToText } from "Utils/convertValue";
import LoadingModal from "Pages/LoadingModal";
import ModalDeleteCourse from "./ModalDeleteCourse";
import IconBack from "Components/IconBack";
import moment from "moment";
import HeaderPrint from "./ExcelComponent/HeaderPrint";
import TablePrint from "./ExcelComponent/TablePrint";
import ModalConfirmUpdate from "./ModalConfirmUpdate";
import ModalEditCourseClh from "./ModalEditCourseClh";

// const {addNewClassSchedule, getCourseTypeName, getCourseSubjects, getInforStudentSaved, getInformationPrint, updateCalender } = actions;
const { getCourseTypeName, getCourseSubjects, getCourseTypeCourse,  getInformationPrint, addAiCurriculum, updateCalender, resetCurriculum , getListCurriculum , resetInfomation} = actions;
const ResultLesson = () => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams()
  const [t] = useTranslation('student');
  const dispatch = useDispatch();
  const {
    maxScore,
    studentID,
    currentStudentInformation,
    scheduleCourse,
    loadingScheduleCourse,
    isAddCourseSuccess,
    inforStudent,
    generatedClassScheduleID,
    teacherComment,
    aspirationalID,
    informationPrint,
    version,
  } = useSelector((state) => state.student);
  const componentRef = useRef(null);
  const onBeforeGetContentResolve = React.useRef(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);
  const [visibleModalEditClh, setVisibleModalEditClh] = useState(false);
  const [visibleModalUpdate, setVisibleModalUpdate] = useState(false);
  const [inputTeacherComment, setInputTeacherComment] = useState("");
  const [listMonth, setListMonth] = useState([]);
  const [listMonthCompare, setListMonthCompare] = useState([]);
  const [weekSelect,setWeekSelect] = useState()
  const [numberMont, setNumberMonth] = useState([]);
  const [dataRow, setDataRow] = useState()
  const [loading, setLoading] = useState({
    exportExcel: false, 
    print: false,
    addNewCourse: false,
    updateCourse:false
  })
  
  const onToggle = () => {
    setVisibleModal(!visibleModal);
  };

  const openModalDelete = () => {
    setVisibleModalDelete(true);
  }

  const cancelModalDelete = () => {
    setVisibleModalDelete(false);
  }

  const onToggleEdit = (week) => {
    if (typeof (week) === 'string') {
      setWeekSelect(week)
    }
    setVisibleModalEdit(!visibleModalEdit);
  };

  const onToggleEditClh = () => {
    setVisibleModalEditClh(!visibleModalEditClh);
  };
  
  const teacherCommentValue = useMemo(() => {
    return teacherComment || ""
  }, [teacherComment]);

  const dataGeneralExam = useMemo(() => {
    return !_.isEmpty(maxScore) ? maxScore[`共通テスト`] : [];
  }, [maxScore]);

  const dataIndividualExam = useMemo(() => {
    return !_.isEmpty(maxScore) ? maxScore[`二次・個別`] : [];
  }, [maxScore]);

  const listScheduleCourse = useMemo(() => {
    return !_.isEmpty(scheduleCourse) ? scheduleCourse : [];
  }, [scheduleCourse]);
  const listNumberOrder = useMemo(() => {
    let arr = [];
    if (scheduleCourse && scheduleCourse.length) {
      for (let i = 1; i <= scheduleCourse.length + 1; i++) {
        arr.push({
          value: i,
          label: i,
        });
      }
      return arr;
    }
  }, [scheduleCourse]);

  useEffect(() => {
    const { startTime, endTime } = currentStudentInformation;
    const dateStart = new Date(startTime)
    const dateEnd = new Date(endTime)
    const date = dateStart.getDate();
    const weekOfMonth = Math.ceil((date) / 7);
    let numberWeekInMonth = weekOfMonth === 5 ? 4 : weekOfMonth
    let numberWeekEnd = Math.ceil((dateEnd.getDate()) / 7) === 5 ? 4 : Math.ceil((dateEnd.getDate()) / 7)
    let numberWeeks = (((dateEnd.getMonth() - dateStart.getMonth()) + (dateEnd.getYear() - dateStart.getYear()) * 12) - 1) * 4 + (5 - numberWeekInMonth) + numberWeekEnd
    const newTimeUnit = []
    const newTimeUnitCompare = []
    const listMonth = []
    let numberWeek = 1
    let numberMonth = new Date(startTime).getMonth() + 1
    let numberYear = new Date(startTime).getFullYear()
    let numberWeekCompare = numberWeekInMonth
    let numberMonthCompare = new Date(startTime).getMonth() + 1
    let numberYearCompare = new Date(startTime).getFullYear()
    for (let i = 0; i < 52; i++) {
      if (numberWeek <= 4) {
        const time = numberMonth < 10 ? `${numberWeek}_0${numberMonth}_${numberYear}` : `${numberWeek}_${numberMonth}_${numberYear}`
        newTimeUnit.push(time)
        
        if (numberMonth === 12 && numberWeek === 4) {
          listMonth.push(numberMonth)
          numberYear++
          numberMonth = 1
          numberWeek = 1
        } else if (numberWeek === 4) {
          listMonth.push(numberMonth)
          numberMonth++
          numberWeek = 1
        } else {
          numberWeek++
        }
      }
    }
    setNumberMonth(listMonth)
    setListMonth(newTimeUnit)
    for (let i = 0; i < numberWeeks; i++) {
      if (numberWeekCompare <= 4) {
        const time = numberMonthCompare < 10 ? `${numberWeekCompare}_0${numberMonthCompare}_${numberYearCompare}` : `${numberWeekCompare}_${numberMonthCompare}_${numberYearCompare}`
        newTimeUnitCompare.push(time)
        
        if (numberMonthCompare === 12 && numberWeekCompare === 4) {
          listMonth.push(numberMonthCompare)
          numberYearCompare++
          numberMonthCompare = 1
          numberWeekCompare = 1
        } else if (numberWeekCompare === 4) {
          numberMonthCompare++
          numberWeekCompare = 1
        } else {
          numberWeekCompare++
        }
      }
    }
    setListMonthCompare(newTimeUnitCompare)
  }, [])

  useEffect(() => {
    if (!studentID && params.id) {
      history.push(`/student/${params.id}`);
    }
  }, [params, studentID]);

  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    // if the form is NOT unchanged, then set the onbeforeunload
    if (studentID) {
      window.addEventListener("beforeunload", handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener("beforeunload", handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => {};
  }, [studentID]);

  const onExportExcel = async () => {
    setLoading({...loading, exportExcel:true})
    const { studentID } = inforStudent;
    let obj = {
      generatedClassScheduleID,
      studentID
    };
    await exportSchedule(obj, currentStudentInformation.studentName);
    setLoading({...loading, exportExcel:false})
  };

  const reactToPrintContent = useCallback(() => {
    return componentRef.current;
  }, [componentRef.current]);

  const handleAfterPrint = React.useCallback(() => {
    setLoading({...loading, print:false})
  }, []);

  const handleOnBeforeGetContent = React.useCallback(() => {
    setLoading({...loading, print:true})
    return new Promise((resolve) => {
      onBeforeGetContentResolve.current = resolve;

      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }, []);

  const handleBeforePrint = React.useCallback(() => {
    setLoading({...loading, print:true})
  }, []);

  const handleReview = (e) => {
    setInputTeacherComment(e.target.value)
  }

  useEffect(() => {
    if (studentID) {
      dispatch(
        getInformationPrint(studentID, (action, res) => {
        })
      )
    }
  }, [])

  const reactToPrintTrigger = React.useCallback(() => {
    // Good
    return <FailureBtn style={{marginLeft: "20px"}} disabled={isAddCourseSuccess} loading={loading.print}>{t("printing")} </FailureBtn>;
  }, [isAddCourseSuccess]);
  const DATA_RESULT_LESSON = [
    {
      title: t("student:studentID"),
      dataIndex: "生徒ID",
      key: "生徒ID",
      data: "生徒ID",
      name: "studentID",
    },
    {
      title: t("student:student_name"),
      dataIndex: "生徒氏名",
      key: "生徒氏名",
      data: "生徒氏名",
      name: "studentName",
    },
    {
      title: t("student:university_name"),
      dataIndex: "大学名",
      key: "大学名",
      data: "大学名",
      name: "universityName",
    },
    {
      title: t("student:faculty_name"),
      dataIndex: "学部名",
      key: "学部名",
      data: "学部名",
      name: "departmentName",
    },
    {
      title: t("student:department_major"),
      dataIndex: "学科・専攻",
      key: "学科・専攻",
      data: "学科・専攻",
      name: "majorName",
    },
    {
      title: t("student:schedule_method"),
      dataIndex: "日程・方式",
      key: "日程・方式",
      data: "日程・方式",
      name: "scheduleMethod",
    },
    {
      title: t("student:course_period"),
      dataIndex: "受講期間",
      key: "受講期間",
      data: "受講期間",
      name: "time",
    },
  ];
  const DATA_CHARACTER = [
    {
      title: t("student:branch_name"),
      dataIndex: "枝名",
      key: "枝名",
      data: "枝名",
      name: "branchAspiration",
    },
    {
      title: t("student:social_science"),
      dataIndex: "文系・理系",
      key: "文系・理系",
      data: "文系・理系",
      name: "socialScience",
    },
    {
      title: t("student:excitement"),
      dataIndex: "奮起度",
      key: "奮起度",
      data: "奮起度",
      name: "奮起度",
    },
    {
      title: t("student:praise_resistance"),
      dataIndex: "誉め耐性",
      key: "誉め耐性",
      data: "誉め耐性",
      name: "誉め耐性",
    },
    {
      title: t("student:stress_tolerance"),
      dataIndex: "ストレス耐性",
      key: "ストレス耐性",
      data: "ストレス耐性",
      name: "ストレス耐性",
    },
    {
      title: t("student:upward_trend"),
      dataIndex: "上昇志向",
      key: "上昇志向",
      data: "上昇志向",
      name: "上昇志向",
    },
    {
      title: t("student:autonomy"),
      dataIndex: "自立性",
      key: "自立性",
      data: "自立性",
      name: "自立性",
    },
  ];

  const onAddNewClassSchedule = () => {
    setLoading({...loading, addNewCourse: true})
    const { studentID, startTime, endTime, scheduleMethod, selectedContent } = currentStudentInformation;
    let course = []
      if(scheduleCourse && scheduleCourse.length){
        scheduleCourse.map(item => {
          let object = {}
          Object.assign(object, { "TIME_UNIT": item[`TIME_UNIT`], "COURSE_ID": item[`番号`] , "CL / h": item[`CL / h`]});
          return item[`TIME_UNIT`] && course.push(object)
        }
        )
      }
    dispatch(
      addAiCurriculum(
        { 
          studentID, 
          startTime, 
          endTime, 
          course, 
          selectedContent,
          scienceType: location?.state?.socialScience,
          scheduleMethod: typeof(scheduleMethod) == 'string' ? scheduleMethod : scheduleMethod[0],
          aspirationCode: location?.state?.aspirationID || aspirationalID, 
          branchAspiration: (location?.state?.branchAspiration === "***" ? "" : location?.state?.branchAspiration) || (currentStudentInformation?.branchAspiration ||  ""),
          teacherComment: inputTeacherComment.trim() || teacherCommentValue.trim(),
          personality: location?.state?.dataCharacter|| currentStudentInformation?.personality,
          version: currentStudentInformation?.version || version,
          curriculumID: generatedClassScheduleID || null,
        },
        (action, res) => {
          if (res.status === RESPONSE_STATUS.SUCCESS) {
            if (params.curriculum === 'create') {
                dispatch(
                  resetInfomation({
                    backupData: false
                  })
                )
                dispatch(
                  resetCurriculum({})
                )
                dispatch(getListCurriculum({
                  studentID : params.id,
                  page: 0,
                  size: 10,
                }, (action, res) => {
                  setLoading(false)
                }))
              }
              // dispatch(getInforStudentSaved(res.data.studentID))
              Notification.success(t("message.register_course_success"));
            }
          setLoading({...loading, addNewCourse:false})
        }
      )
    );
  };

  useEffect(() => {
    dispatch(getCourseTypeName())
    dispatch(getCourseSubjects())
    dispatch(getCourseTypeCourse())

  },[])
  const handleUpdateCalender = () => {
    setVisibleModalUpdate(true)
  }
  const handleUpdate = () => {
    setLoading({...loading, updateCourse:true})
    const { startTime, endTime } = currentStudentInformation;
    const newScheduleCourse = scheduleCourse.map(obj => {
      const { key,newCourse, ...rest } = obj;
      return rest;
    });
    dispatch(updateCalender({
      startTime: moment(startTime).format('YYYY-MM-DD HH:mm:ss'),
      endTime: moment(endTime).format('YYYY-MM-DD HH:mm:ss'),
      course:newScheduleCourse,
      aspirationalID,
      version,
      teacherComment
    },
    (action, res) => {
      if (res.status === RESPONSE_STATUS.SUCCESS) {
          Notification.success(t("message.update_course_success"));
        }
      setLoading({ ...loading, updateCourse: false })
      setVisibleModalUpdate(false)
    }
    ))
  }
  // const handleResetForm = () => {
  //   inforStudent?.generatedClassScheduleID === null && dispatch(backHome())
  // }

  return (
    <Wrap>
      <div>
        <ActionBreadcrumb>
          {/* <BreadcrumbComponent idStudent={currentStudentInformation?.studentID} name={t("student_information")} clearStore={true} /> */}
          <BreadcrumbComponent className='backHome' idStudent={currentStudentInformation?.studentID} icon={<IconBack/>} name={t("student_information")} clearStore={!!inforStudent.generatedClassScheduleID} />
        </ActionBreadcrumb>
        <InfomationStudent>
          <Row gutter={[16, 16]}>
            {DATA_RESULT_LESSON.map((it) => (
              <ColWraper xl={3} lg={8} sm={12} xs={24} key={it.key}>
                <label htmlFor="html" className="info_title">
                  {it.title}
                </label>
                <span className="info_data">
                  {it.name === "time"
                    ? `${dayjs(currentStudentInformation[`startTime`]).format('YYYY/MM/DD') || ""} ~ ${
                      dayjs(currentStudentInformation[`endTime`]).format('YYYY/MM/DD') || ""
                      }`
                    : currentStudentInformation[`${it.name}`]}
                </span>
              </ColWraper>
            ))}
          </Row>
          <Row className="info_character" gutter={[16, 16]}>
            {DATA_CHARACTER.map((it) =>
            (
              <ColWraper xl={3} lg={8} sm={12} xs={24} key={it.key} className={it.name === "branchAspiration" && (currentStudentInformation["branchAspiration"] === undefined || currentStudentInformation["branchAspiration"] === null) ? "hidden_col" : ""}>
                {(it.name === "branchAspiration" || it.name === "socialScience") ? (
                    (currentStudentInformation[`${it.name}`] !== undefined || currentStudentInformation[`${it.name}`] !== null) && 
                    <>
                      <label htmlFor="html" className="info_title">
                        {it.title}
                      </label>
                      <span className="info_data">
                        {currentStudentInformation[`${it.name}`]}
                      </span>
                    </>
                  ) : (
                    <>
                      <label htmlFor="html" className="info_title">
                        {it.title}
                      </label>
                      <span className="info_data">
                        {
                          (!_.isEmpty(currentStudentInformation?.personality))?
                          convertValueToText(currentStudentInformation.personality[it.name]) :
                          convertValueToText(location?.state?.dataCharacter?.[it.name])
                        }
                      </span>
                    </>
                  )
                }
              </ColWraper>
            ))}
          </Row>
        </InfomationStudent>


        <Row gutter={[32]}>
          <Col lg={8} sm={24} xs={24}>
            <TableScoreComponent
              columns={dataGeneralExam?.nameCourse}
              dataSource={dataGeneralExam?.valueCourse}
              titleTable={t("common_test")}
            />
          </Col>
          <Col lg={8} sm={24} xs={24}>
            <TableScoreComponent
              columns={dataIndividualExam?.nameCourse}
              dataSource={dataIndividualExam?.valueCourse}
              titleTable={t("secondary_individual")}
            />
          </Col>
          <ColButton lg={8} sm={24} xs={24}>
            <SuccessBtn onClick={onToggle}>+ {t("add_new_course")}</SuccessBtn>
            <SuccessBtn className={listScheduleCourse.length > 0 ? "" : "disable"} onClick={openModalDelete}>{t("delete_all")}</SuccessBtn>
          </ColButton>
        </Row>
        <InputWrapper size="large" addonBefore={t("remarks")}  defaultValue={teacherCommentValue || ""} onBlur={handleReview}/>
        <UpdateBtn onClick={handleUpdateCalender}>{t("btn_update_calender")}</UpdateBtn>
        <TableResult
            dataSource={listScheduleCourse}
            loading={loadingScheduleCourse}
            onToggleEdit={onToggleEdit}
            setDataRow={setDataRow}
            listMonth={listMonth}
            numberMont={numberMont}
            onToggleEditClh={onToggleEditClh}
          />
        <div ref={componentRef} className="component_print">
          <HeaderPrint 
            currentStudentInformation={currentStudentInformation}
            informationPrint={informationPrint}
            dataSource={listScheduleCourse}
            teacherCommentValue={teacherCommentValue}
          />
          <TablePrint 
            dataSource={listScheduleCourse}
          />
        </div>
      </div>

      <Row justify={"space-around"} className="footer_action">
        <Col span={24} lg={12}>
          <FailureBtn loading={loading.addNewCourse} onClick={onAddNewClassSchedule} disabled={!scheduleCourse.length || loading.addNewCourse}>{t("register")} </FailureBtn>
          <SuccessBtn className={listScheduleCourse.length > 0 ? "btn_delete" : "btn_delete disable"} onClick={openModalDelete}>{t("delete_all")}</SuccessBtn>
        </Col>
        <GroupButton span={24} lg={12}>
          <FailureBtn onClick={onExportExcel} loading={loading.exportExcel} disabled={loading.exportExcel || isAddCourseSuccess || !scheduleCourse.length}>{t("excel_extract")}</FailureBtn>
          {/* <div style={{ width: "2rem" }}></div> */}
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </GroupButton>
      </Row>
      <ModalAddCourse
        listScheduleCourse={listScheduleCourse}
        visibleModal={visibleModal}
        onOk={onToggle}
        onCancel={onToggle}
        listNumberOrder={listNumberOrder}
        currentStudentInformation={currentStudentInformation}
      ></ModalAddCourse>
      {visibleModalDelete && <ModalDeleteCourse
        listScheduleCourse={listScheduleCourse}
        visibleModal={visibleModalDelete}
        onOk={cancelModalDelete}
        onCancel={cancelModalDelete}
        setVisibleModalDelete={setVisibleModalDelete}
      ></ModalDeleteCourse>}
      {/* <ModalConfirmDelete
        visibleModal={visibleModalDelete}
        onOk={handleDeletedCourse}
        onCancel={cancelModalDelete}
      ></ModalConfirmDelete> */}
      {visibleModalEdit && <ModalEditCourse
        DATA_RESULT_LESSON={DATA_RESULT_LESSON}
        visibleModalEdit={visibleModalEdit}
        onOk={onToggleEdit}
        onCancel={onToggleEdit}
        currentStudentInformation={currentStudentInformation}
        dataRow={dataRow}
        listMonthCompare={listMonthCompare}
        weekSelect={weekSelect}
        listScheduleCourse={listScheduleCourse}
      ></ModalEditCourse>}
      {visibleModalEditClh && <ModalEditCourseClh
        visibleModalEditClh={visibleModalEditClh}
        onOk={onToggleEditClh}
        onCancel={onToggleEditClh}
        dataRow={dataRow}
        listMonthCompare={listMonthCompare}
        listScheduleCourse={listScheduleCourse}
      ></ModalEditCourseClh>}
      <ModalConfirmUpdate
        visibleModal={visibleModalUpdate}
        onOk={handleUpdate}
        onCancel={()=>{setVisibleModalUpdate(false)}}
      />

      {loading.exportExcel && <LoadingModal textLoading={t("excel_extracting")} cancelEvent={handleAfterPrint} />}
      {loading.addNewCourse && <LoadingModal textLoading={t("save_course")} />}
      {loading.updateCourse && <LoadingModal  />}
    </Wrap>
  );
};

export default ResultLesson;
